/// <reference path="../references.ts"/>
var awoComponents = {
    /**
     * Tabbed container component swiper instance
     */
    tabbedContainerSwiperInstance: null,
    /**
     * Video player instances
     */
    videoPlayerinstances: [],
    /**
     * Initialise all the components sitewide
     */
    init: function () {
        awoComponents.initTabbedContainerComponent();
        awoComponents.initVideoPlayerComponent();
    },
    /**
     * Initialise tabbed container component
     */
    initTabbedContainerComponent: function () {
        awoComponents.tabbedContainerSwiperInstance = new Swiper('.awo-tabbed-container-swiper', {
            spaceBetween: 20,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoHeight: true,
        });
    },
    /**
     * Tabbed container functionality implemented using
     * Alpine. Depends on initTabbedContainerComponent.
     *
     * @returns object
     */
    alpineTabbedContainerComponent: function () {
        return {
            activeIndex: 0,
            change: function (index) {
                var swiper = awoComponents.tabbedContainerSwiperInstance;
                swiper.slideTo(index);
                this.activeIndex = index;
            },
        };
    },
    /**
     * Documents items row component load items
     * in fragments
     *
     * @returns object
     */
    alpineDocumentItemsRowComponent: function () {
        return {
            initItems: 5,
            active: 5,
            items: null,
            init: function () {
                var _this = this;
                this.items = document.querySelectorAll('.document-items-row__item');
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 5; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    /**
     * Accordion component load items
     * in fragments
     *
     * @returns object
     */
    alpineAccordionComponent: function () {
        return {
            initItems: 10,
            active: 10,
            items: null,
            init: function () {
                var _this = this;
                this.items = document.querySelectorAll('.awo-accordion__item-head');
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 5; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    alpineTwoColLinksComponent: function () {
        return {
            initItems: 10,
            active: 10,
            items: null,
            xinit: function (itemCount) {
                var _this = this;
                if (itemCount === void 0) { itemCount = 10; }
                this.items = document.querySelectorAll('.awo-two-col-links__grid > .awo-two-col-links__grid-item');
                this.initItems = itemCount;
                this.active = itemCount;
                this.items.forEach(function (itemEl, index) {
                    if (index < _this.initItems) {
                        itemEl.classList.remove('hidden');
                    }
                });
            },
            show: function (limit) {
                var _this = this;
                if (limit === void 0) { limit = 10; }
                this.items.forEach(function (itemEl, index) {
                    if (index < (_this.active + limit)) {
                        itemEl.classList.remove('hidden');
                    }
                });
                this.active = this.active + limit;
            },
        };
    },
    /**
     * Initialise video player components
     */
    initVideoPlayerComponent: function () {
        var videos = document.querySelectorAll('.awo-video-player__button');
        var isIPhone = navigator.userAgent.indexOf('iPhone') > -1;
        videos.forEach(function (el) {
            awoComponents.videoPlayerinstances[el.getAttribute('player-id')] = GLightbox({
                elements: [
                    {
                        'href': el.getAttribute('video-url'),
                        'type': 'video',
                    },
                ],
                autoplayVideos: isIPhone ? false : true,
                plyr: {
                    config: {
                        muted: false,
                        youtube: {
                            autoplay: 0,
                            playsinline: 1,
                        },
                    }
                }
            });
            el.addEventListener('click', function (e) {
                e.preventDefault();
                awoComponents.videoPlayerinstances[this.getAttribute('player-id')].open();
            });
        });
    },
    /**
     * Animate three numbers component
     *
     * @param element parent element object
     */
    threeNumbersComponent: function (element) {
        var counterUp = window.counterUp.default;
        var numbers = element.querySelectorAll('.awo-three-numbers__grid-item__name');
        numbers.forEach(function (el) {
            counterUp(el, {
                duration: 2000,
                delay: 16
            });
        });
    },
    alpineTabbedContent: function () {
        return {
            activeIndex: 1,
            swiper: null,
            init: function () {
                var _this = this;
                this.swiper = new Swiper('.awo-tabbed-content-swiper', {
                    allowTouchMove: false,
                    autoHeight: true,
                });
                var hash = window.location.hash.substring(1);
                if (hash) {
                    var tabIndex = Array.from(document.querySelectorAll('.awo-tac__header a')).findIndex(function (el) { return el.getAttribute('href').substring(1) === hash; }) + 1;
                    if (tabIndex > 0) {
                        this.changeTab(tabIndex);
                    }
                }
                else {
                    this.swiper.slideTo(0);
                }
                window.addEventListener('updateAlpineTabbedContentHeight', function (e) {
                    _this.swiper.updateAutoHeight(300);
                });
            },
            changeTab: function (index) {
                if (this.activeIndex == index) {
                    this.activeIndex = null;
                    history.pushState(null, "", window.location.pathname);
                    return;
                }
                this.activeIndex = index;
                this.swiper.slideTo(index - 1);
                var tabEl = document.querySelector("[data-id=\"" + index + "\"]");
                var tabName = tabEl.getAttribute('href');
                history.pushState(null, "", tabName);
                setTimeout(function () {
                    tabEl.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest"
                    });
                }, 1000);
            }
        };
    },
    alpineImageTextCarousel: function (uid) {
        return {
            swiper: null,
            init: function () {
                var el = this.$el.querySelector(".awo-image-text-carousel" + uid);
                this.swiper = new Swiper(el, {
                    autoplay: {
                        delay: 5000,
                    },
                    pagination: {
                        el: ".awo-image-text-carousel" + uid + "-pagination",
                        type: 'bullets',
                        clickable: true,
                    },
                    spaceBetween: 32,
                });
            },
            next: function () {
                this.swiper.slideNext();
            },
            prev: function () {
                this.swiper.slidePrev();
            }
        };
    },
    alpineAccordion: function () {
        return {
            activeIndex: null,
            init: function () {
                this.autoResize();
            },
            toggle: function (index) {
                var el = this.$el.closest('dl').querySelector("[data-item=\"" + index + "\"]");
                var height = el.scrollHeight;
                if (this.activeIndex == index) {
                    this.activeIndex = null;
                    el.removeAttribute('style');
                    this.resizeSwiper();
                    return;
                }
                var allAccordionItems = this.$el.closest('.awo-acc').querySelectorAll('[data-item]');
                allAccordionItems.forEach(function (el) {
                    el.removeAttribute('style');
                });
                el.setAttribute('style', "height: " + height + "px;");
                this.resizeSwiper();
                this.activeIndex = index;
            },
            autoResize: function () {
                var _this = this;
                window.addEventListener('resize', function () {
                    var el = _this.$el.querySelector("[data-item=\"" + _this.activeIndex + "\"]");
                    if (el == null) {
                        return;
                    }
                    var container = el.querySelector('.awo-sa__item-content-container');
                    if (container == null) {
                        return;
                    }
                    var height = container.scrollHeight;
                    el.setAttribute('style', "height: " + height + "px;");
                    awoComponents.alpineAccordion().resizeSwiper();
                });
            },
            resizeSwiper: function () {
                /**
                 * Using timeout to wait for animation end
                 * and fire tab content update event
                 */
                setTimeout(function () {
                    window.dispatchEvent(new CustomEvent('updateAlpineTabbedContentHeight', {
                        detail: {
                            update: true
                        }
                    }));
                }, 400);
            }
        };
    },
    alpineStepAccordion: function () {
        return {
            activeIndex: null,
            init: function () {
                this.autoResize();
            },
            toggle: function (index) {
                var el = this.$el.closest('dl').querySelector("[data-item=\"" + index + "\"]");
                var height = el.scrollHeight;
                if (this.activeIndex == index) {
                    this.activeIndex = null;
                    el.removeAttribute('style');
                    awoComponents.alpineAccordion().resizeSwiper();
                    return;
                }
                var allAccordionItems = this.$el.closest('.awo-sa').querySelectorAll('[data-item]');
                allAccordionItems.forEach(function (el) {
                    el.removeAttribute('style');
                });
                el.setAttribute('style', "height: " + height + "px;");
                awoComponents.alpineAccordion().resizeSwiper();
                this.activeIndex = index;
            },
            autoResize: function () {
                var _this = this;
                window.addEventListener('resize', function () {
                    var el = _this.$el.querySelector("[data-item=\"" + _this.activeIndex + "\"]");
                    if (el == null) {
                        return;
                    }
                    var container = el.querySelector('.awo-sa__item-content-container');
                    if (container == null) {
                        return;
                    }
                    var height = container.scrollHeight;
                    el.setAttribute('style', "height: " + height + "px;");
                    awoComponents.alpineAccordion().resizeSwiper();
                });
            }
        };
    }
};
